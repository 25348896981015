<template>
  <div class="home ml-16 mr-16 mt-5">

<h1>CHAPITRE I</h1>
<h1>LE <a href="https://www.google.com/search?q=SCARABE%CC%81E+SACRE%CC%81&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiDzIzys-jtAhXMGaYKHbigCscQ_AUoAXoECAUQAw&biw=859&bih=561" target=_blank rel=noopener>SCARABÉE SACRÉ</a></h1>

<p>Les choses se passèrent <Tooltip label="このように">ainsi</Tooltip>. 
  Nous étions cinq ou six : moi le plus vieux, leur maître, mais encore plus leur compagnon et leur ami ; eux, jeunes gens à cœur chaleureux, à <Tooltip label="のどかな">riante</Tooltip> imagination, <Tooltip label="溢れんばかりの">débordant</Tooltip> de cette <Tooltip label="精気、活力">sève</Tooltip> <Tooltip label="春の">printanière</Tooltip> de la vie qui nous rend si <Tooltip label="開放的な">expansifs</Tooltip> et si désireux de connaître. 
  <Tooltip label="[deviser]打ち解けて話し合う">Devisant</Tooltip> de choses et d'autres, 
  par un <Tooltip label="小道、細道">sentier</Tooltip> <Tooltip label="沿って">bordé d</Tooltip>'<Tooltip label="ニワトコ">hyèbles</Tooltip> et d'<a href="https://www.google.com/search?q=aubépines&tbm=isch" target=_blank rel=noopener>aubépines</a>, 
  où déjà la <Tooltip label="ハナムグリ">Cétoine dorée</Tooltip> <Tooltip label="[s'enivrer]酔う">s'enivrait</Tooltip> d'amères <Tooltip label="芳香、香気">senteurs</Tooltip> sur les <Tooltip label="散房花序">corymbes</Tooltip> épanouis,
  on allait voir si le Scarabée sacré avait fait sa première <Tooltip label="登場">apparition</Tooltip> au plateau <Tooltip label="砂で覆われた">sablonneux</Tooltip> des Angles<a href="#footnote-1"><sup>1</sup></a>, 
  et roulait sa pilule de <Tooltip label="牛糞">bouse</Tooltip>, 
  image du monde pour la vieille Égypte ; on allait <Tooltip label="[si〜]について問い合わせる、の情報を集める">s'informer si</Tooltip> les <Tooltip label="湧き水、清流">eaux vives</Tooltip> de la base de la <Tooltip label="丘">colline</Tooltip> n'<Tooltip label="保護する">abritaient</Tooltip> point, 
  sous leur <Tooltip label="一面の、[比喩的に]絨毯">tapis</Tooltip> de <a href="https://www.google.com/search?q=lentilles+aquatiques&tbm=isch" target=_blank rel=noopener>lentilles aquatiques</a>, 
  de <a href="https://www.google.com/search?q=jeunes+tritons&tbm=isch" target=_blank rel=noopener>jeunes tritons</a>, 
  dont les <Tooltip label="鰓">branchies</Tooltip> ressemblent à de <Tooltip label="細かい">menus</Tooltip> <Tooltip label="小枝、支脈">rameaux</Tooltip> de corail ; 
  si l'<a href="https://www.google.com/search?q=e%CC%81pinoche&tbm=isch" target=_blank rel=noopener>épinoche</a>, 
  l'élégant petit poisson des <Tooltip label="せせらぎ">ruisselets</Tooltip>, 
  avait mis sa <a href="https://www.google.com/search?q=Cravate+de+Noces+(Code+de+la+Cravate,+1828)&source=lnms&tbm=isch" target=_blank rel=noopener>cravate de noces</a>, 
  azur et pourpre ; si, de son aile <Tooltip label="鋭い、尖った">aiguë</Tooltip>, 
  l'<Tooltip label="ツバメ">hirondelle</Tooltip>, nouvellement arrivée, <Tooltip label="かすめる">effleurait</Tooltip> la <Tooltip label="草地、牧草地">prairie</Tooltip>, <Tooltip label="追い回す、[執拗に]追跡する">pourchassant</Tooltip> les <Tooltip label="ガガンボ">tipules</Tooltip>, qui <Tooltip label="まく、撒き散らす、ばらまく">sèment</Tooltip> leurs œufs en dansant ;
  si, sur le <Tooltip label="入り口">seuil</Tooltip> d'un <Tooltip label="巣穴">terrier</Tooltip> <Tooltip label="空洞の">creusé</Tooltip> dans le <Tooltip label="砂岩">grès</Tooltip>, 
  le <a href="https://www.google.com/search?q=lézard+ocellé&source=lnms&tbm=isch">lézard ocellé</a> étalait au soleil sa <Tooltip label="臀部">croupe</Tooltip> <Tooltip label="[〜を]散りばめた">constellée de</Tooltip> <Tooltip label="斑点">taches</Tooltip> bleues ; 
  si la <a href="https://www.google.com/search?q=mouette+rieuse=lnms&tbm=isch">mouette rieuse</a>, 
  venue de la mer <Tooltip label="…に続いて、…の後に">à la suite des</Tooltip> <Tooltip label="…の群れ">légions de</Tooltip> poissons qui remontent le Rhône pour <Tooltip label="産卵する">frayer</Tooltip> dans ses eaux, 
  <Tooltip label="滑空する">planait</Tooltip> par bandes sur le <Tooltip label="大河">fleuve</Tooltip> en jetant <Tooltip label="断続的に">par intervalles</Tooltip> son cri pareil à l'<Tooltip label="はじけるような笑い声">éclat de rire</Tooltip> d'un maniaque ; 
  si... mais tenons-nous-en là ; <Tooltip label="手短に言えば">pour abréger,</Tooltip> disons que, gens simples et naïfs, prenant un vif plaisir à vivre avec les bêtes, nous allions passer une <Tooltip label="朝、午前中">matinée</Tooltip> à la fête <Tooltip label="えも言われぬ">ineffable</Tooltip> du réveil de la vie au printemps.</p>
<p id="footnote-1"><sup>1</sup> Village du Gard, en face d'Avignon.</p>
<p>Les événements répondirent à nos espérances. L'épinoche avait fait sa toilette ; ses écailles eussent fait pâlir l'éclat de l'argent ; sa gorge était frottée du plus vif vermillon. À l'approche
de l'aulastome, grosse sangsue noire mal intentionnée, sur le
dos, sur les flancs, ses aiguillons brusquement se dressaient,
comme poussés par un ressort. Devant cette attitude déterminée, le bandit se laisse honteusement couler parmi les herbages.
La gent béate des mollusques, planorbes, physes, limnées, humait l'air à la surface des eaux. L'hydrophile et sa hideuse larve,
pirates des mares, tantôt à l'un tantôt à l'autre en passant tordaient le cou. Le stupide troupeau ne paraissait pas même s'en
apercevoir. Mais laissons les eaux de la plaine et gravissons la
falaise qui nous sépare du plateau. Là-haut, des moutons pâturent, des chevaux s'exercent aux courses prochaines, tous distribuant la manne aux bousiers en liesse. </p>
<p>Voici à l'œuvre les coléoptères vidangeurs à qui est dévolue
la haute mission d'expurger le sol de ses immondices. On ne se
lasserait pas d'admirer la variété d'outils dont ils sont munis,
soit pour remuer la matière stercorale, la dépecer, la façonner,
soit pour creuser de profondes retraites où ils doivent s'enfermer avec leur butin. Cet outillage est comme un musée technologique, où tous les instruments de fouille seraient représentés.
Il y a là des pièces qui semblent imitées de celles de l'industrie
humaine ; il y en a d'autres d'un type original, où nous pourrions nous-mêmes prendre modèle pour de nouvelles combinaisons. </p>
<p>Le Copris espagnol porte sur le front une vigoureuse corne,
pointue et recourbée en arrière, pareille à la longue branche
d'un pic. À semblable corne, le Copris lunaire adjoint deux fortes pointes taillées en soc de charrue, issues du thorax ; et entre
les deux, une protubérance à arête vive faisant office de large racloir. Le Bubas Bubale et le Bubas Bison, tous les deux confinés aux bords de la Méditerranée, sont armés sur le front de
deux robustes cornes divergentes, entre lesquelles s'avance un
soc horizontal fourni par le corselet. Le Minotaure Typhée porte
sur le devant du thorax, trois pointes d'araire, parallèles et dirigées en avant, les latérales plus longues, la médiane plus courte.
L'Onthophage taureau a pour outil deux pièces longues et courbes qui rappellent les cornes d'un taureau ; l'Onthophage fourchu a pour sa part une fourche à deux branches, dressées
d'aplomb sur sa tête aplatie. Le moins avantagé est doué, tantôt
sur la tête, tantôt sur le corselet, de tubercules durs, outils obtus
que la patience de l'insecte sait toutefois très-bien utiliser. Tous
sont armés de la pelle, c'est-à-dire qu'ils ont la tête large, plate
et à bord tranchant ; tous font usage du râteau, c'est-à-dire
qu'ils recueillent avec leurs pattes antérieures dentelées.</p>
<p>Comme dédommagement à sa besogne ordurière, plus d'un
exhale l'odeur forte du musc, et brille sous le ventre du reflet
des métaux polis. Le Géotrupe hypocrite a par dessous l'éclat du
cuivre et de l'or ; le Géotrupe stercoraire a le ventre d'un violet
améthyste. Mais, en général, leur coloration est le noir. C'est
aux régions tropicales qu'appartiennent les bousiers splendidement costumés, véritables bijoux vivants. Sous les bouses de
chameau, la Haute-Égypte nous présenterait tel Scarabée qui
rivalise avec le vert éclatant de l'émeraude ; la Guyane, le Brésil,
le Sénégal, nous montreraient tels Copris d'un rouge métallique,
aussi riche que celui du cuivre, aussi vif que celui du rubis. Si cet
écrin de l'ordure nous manque, les bousiers de nos pays ne sont
pas moins remarquables par leurs mœurs. </p>
<p>Quel empressement autour d'une même bouse ! Jamais
aventuriers accourus des quatre coins du monde n'ont mis telle
ferveur à l'exploitation d'un placer californien. Avant que le soleil soit devenu trop chaud, ils sont là par centaines, grands et
petits, pêle-mêle, de toute espèce, de toute forme, de toute taille,
se hâtant de se tailler une part dans le gâteau commun. Il y en a qui travaillent à ciel ouvert, et ratissent la surface ; il y en a qui
s'ouvrent des galeries dans l'épaisseur même du monceau, à la
recherche des filons de choix ; d'autres exploitent la couche inférieure pour enfouir sans délai leur butin dans le sol sousjacent ; d'autres, les plus petits, émiettent à l'écart un lopin
éboulé des grandes fouilles de leurs forts collaborateurs. Quelques-uns, les nouveaux venus et les plus affamés sans doute,
consomment sur place ; mais le plus grand nombre songe à se
faire un avoir qui lui permette de couler de longs jours dans
l'abondance, au fond d'une sûre retraite. Une bouse, fraîche à
point, ne se trouve pas quand on veut au milieu des plaines stériles du thym ; telle aubaine est une vraie bénédiction du ciel ;
les favorisés du sort ont seuls un pareil lot. Aussi les richesses
d'aujourd'hui sont-elles prudemment mises en magasin. Le fumet stercoraire a porté l'heureuse nouvelle à un kilomètre à la
ronde, et tous sont accourus s'amasser des provisions. Quelques
retardataires arrivent encore, au vol ou pédestrement. </p>
<p>Quel est celui-ci qui trottine vers le monceau, craignant
d'arriver trop tard ? Ses longues pattes se meuvent avec une
brusque gaucherie, comme poussées par une mécanique que
l'insecte aurait dans le ventre ; ses petites antennes rousses
épanouissent leur éventail, signe d'inquiète convoitise. Il arrive,
il est arrivé, non sans culbuter quelques convives. C'est le Scarabée sacré, tout de noir habillé, le plus gros et le plus célèbre de
nos bousiers. Le voilà attablé, côte à côte avec ses confrères, qui,
du plat de leurs larges pattes antérieures, donnent à petits
coups la dernière façon à leur boule, ou bien l'enrichissent d'une
dernière couche avant de se retirer et d'aller jouir en paix du
fruit de leur travail. Suivons dans toutes ses phases la confection de la fameuse boule. </p>
<p>Le chaperon, c'est-à-dire le bord de la tête, large et plate,
est crénelé de six dentelures angulaires rangées en demi-cercle.
C'est là l'outil de fouille et de dépècement, le râteau qui soulève
et rejette les fibres végétales non nutritives, va au meilleur, le ratisse et le rassemble. Un choix est ainsi fait, car pour ces fins
connaisseurs, ceci vaut mieux que cela ; choix par à peu près, si
le Scarabée s'occupe de ses propres victuailles, mais d'une scrupuleuse rigueur s'il faut confectionner la boule maternelle, creusée d'une niche centrale où l'œuf doit éclore. Alors tout brin fibreux est soigneusement rejeté, et la quintessence stercoraire
seule cueillie pour bâtir la couche interne de la cellule. À sa sortie de l'œuf, la jeune larve trouve ainsi, dans la paroi même de
sa loge, un aliment raffiné qui lui fortifie l'estomac et lui permet
d'attaquer plus tard les couches externes et grossières. </p>
<p>Pour ses besoins à lui, le Scarabée est moins difficile, et se
contente d'un triage en gros. Le chaperon dentelé éventre donc
et fouille, élimine et rassemble un peu au hasard. Les jambes
antérieures concourent puissamment à l'ouvrage. Elles sont
aplaties, courbées en arc de cercle, relevées de fortes nervures et
armées en dehors de cinq robustes dents. Faut-il faire acte de
force, culbuter un obstacle, se frayer une voie au plus épais du
monceau, le bousier joue des coudes, c'est-à-dire qu'il déploie
de droite et de gauche ses jambes dentelées, et d'un vigoureux
coup de râteau déblaie une demi-circonférence. La place faite,
les mêmes pattes ont un autre genre de travail : elles recueillent
par brassées la matière râtelée par le chaperon et la conduisent
sous le ventre de l'insecte, entre les quatre pattes postérieures.
Celles-ci sont conformées pour le métier de tourneur. Leurs
jambes, surtout celles de la dernière paire, sont longues et fluettes, légèrement courbées en arc et terminées par une griffe trèsaiguë. Il suffit de les voir pour reconnaître en elles un compas
sphérique, qui, dans ses branches courbes, enlace un corps globuleux pour en vérifier, en corriger la forme. Leur rôle est, en
effet, de façonner la boule. </p>
<p>Brassée par brassée, la matière s'amasse sous le ventre, entre les quatre jambes, qui, par une simple pression, lui communiquent leur propre courbure et lui donnent une première façon. Puis, par moments, la pilule dégrossie est mise en branle entre les quatre branches du double compas sphérique ; elle
tourne sous le ventre du bousier et se perfectionne par la rotation. Si la couche superficielle manque de plasticité et menace
de s'écailler, si quelque point trop filandreux n'obéit pas à l'action du tour, les pattes antérieures retouchent les endroits défectueux ; à petits coups de leurs larges battoirs, elles tapent la
pilule pour faire prendre corps à la couche nouvelle et emplâtrer
dans la masse les brins récalcitrants.</p>
<p>Par un soleil vif, quand l'ouvrage presse, on est émerveillé
de la fébrile prestesse du tourneur. Aussi la besogne marche-telle vite : c'était tantôt une maigre pilule, c'est maintenant une
bille de la grosseur d'une noix, ce sera tout à l'heure une boule
de la grosseur d'une pomme. J'ai vu des goulus en confectionner
de la grosseur du poing. Voilà certes sur la planche du pain pour
quelques jours.</p>
<p>Les provisions sont faites ; il s'agit maintenant de se retirer
de la mêlée et d'acheminer les vivres en lieu opportun. Là,
commencent les traits de mœurs les plus frappants du Scarabée.
Sans délai, le bousier se met en route ; il embrasse la sphère de
ses deux longues jambes postérieures, dont les griffes terminales, implantées dans la masse, servent de pivots de rotation ; il
prend appui sur les jambes intermédiaires, et faisant levier avec
les brassards dentelés des pattes de devant, qui tour à tour pressent sur le sol, il progresse à reculons avec sa charge, le corps
incliné, la tête en bas, l'arrière-train en haut. Les pattes postérieures, organe principal de la mécanique, sont dans un mouvement continuel ; elles vont et viennent, déplaçant la griffe
pour changer l'axe de rotation, maintenir la charge en équilibre
et la faire avancer par les poussées alternatives de droite et de
gauche. À tour de rôle, la boule se trouve de la sorte en contact
avec le sol par tous les points de sa surface, ce qui la perfectionne dans sa forme et donne consistance égale à sa couche
extérieure par une pression uniformément répartie. </p>
<p>Et hardi ! Ça va, ça roule ; on arrivera, non sans encombre
cependant. Voici un premier pas difficile : le bousier s'achemine
en travers d'un talus, et la lourde masse tend à suivre la pente ;
mais l'insecte, pour des motifs à lui connus, préfère croiser cette
voie naturelle, projet audacieux dont l'insuccès dépend d'un
faux pas, d'un grain de sable troublant l'équilibre. Le faux pas
est fait, la boule roule au fond de la vallée ; l'insecte, culbuté par
l'élan de la charge, gigote, se remet sur ses jambes et accourt
s'atteler. La mécanique fonctionne de plus belle. – Mais prends
donc garde, étourdi ; suis le creux du vallon, qui t'épargnera
peine et mésaventure ; le chemin y est bon, tout uni ; ta pilule y
roulera sans effort. – Eh bien non : l'insecte se propose de remonter le talus qui lui a été fatal. Peut-être lui convient-il de
regagner les hauteurs. À cela je n'ai rien à dire ; l'opinion du
Scarabée est plus clairvoyante que la mienne sur l'opportunité
de se tenir en haut lieu. – Prends au moins ce sentier, qui, par
une pente douce, te conduira là-haut. – Pas du tout, s'il se
trouve à proximité quelque talus bien raide, impossible à remonter, c'est celui-là que l'entêté préfère. Alors commence le
travail de Sisyphe. La boule, fardeau énorme, est péniblement
hissée, pas à pas, avec mille précautions, à une certaine hauteur,
toujours à reculons. On se demande par quel miracle de statique
une telle masse peut être retenue sur la pente. Ah ! un mouvement mal combiné met à néant tant de fatigue : la boule dévale
entraînant avec elle le Scarabée. L'escalade est reprise, bientôt
suivie d'une nouvelle chute. La tentative recommence, mieux
conduite cette fois aux passages difficiles ; une maudite racine
de gramen, cause des précédentes culbutes, est prudemment
tournée. Encore un peu, et nous y sommes ; mais doucement,
tout doucement. La rampe est périlleuse et un rien peut tout
compromettre. Voilà que la jambe glisse sur un gravier poli. La
boule redescend pêle-mêle avec le bousier. Et celui-ci de recommencer avec une opiniâtreté que rien ne lasse. Dix fois,
vingt fois, il tentera l'infructueuse escalade, jusqu'à ce que son
obstination ait triomphé des obstacles, ou que, mieux avisé et reconnaissant l'inutilité de ses efforts, il adopte le chemin en
plaine. </p>
<p>Le Scarabée ne travaille pas toujours seul au charroi de la
précieuse pilule : fréquemment, il s'adjoint un confrère ; ou,
pour mieux dire, c'est le confrère qui s'adjoint. Voici comment
d'habitude se passe la chose. – Sa boule préparée, un bousier
sort de la mêlée et quitte le chantier, poussant à reculons son
butin. Un voisin, des derniers venus, et dont la besogne est à
peine ébauchée, brusquement laisse là son travail et court à la
boule roulante, prêter main forte à l'heureux propriétaire, qui
paraît accepter bénévolement le secours. Désormais, les deux
compagnons travaillent en associés. À qui mieux mieux, ils
acheminent la pilule en lieu sûr. Y a-t-il eu pacte, en effet, sur le
chantier, convention tacite de se partager le gâteau ? Pendant
que l'un pétrissait et façonnait la boule, l'autre ouvrait-il de riches filons pour en extraire des matériaux de choix et les adjoindre aux provisions communes ? Je n'ai jamais surpris pareille collaboration ; j'ai toujours vu chaque bousier exclusivement occupé de ses propres affaires sur les lieux d'exploitation.
Donc, pour le dernier venu, aucun droit acquis. </p>
<p>Serait-ce alors une association des deux sexes, un couple
qui va se mettre en ménage ? Quelque temps, je l'ai cru. Les
deux bousiers, l'un par devant, l'autre par derrière, poussant
d'un même zèle la lourde pelote, me rappelaient certains couplets que moulinaient dans le temps les orgues de Barbarie.
« Pour monter notre ménage, hélas ! comment ferons-nous. –
Toi devant et moi derrière, nous pousserons le tonneau. » – De
par le scalpel, il m'a fallu renoncer à cette idylle de famille. Chez
les Scarabées, les deux sexes ne se distinguent l'un de l'autre par
aucune différence extérieure. J'ai donc soumis à l'autopsie les
deux bousiers occupés au charroi d'une même boule ; et trèssouvent, ils se sont trouvés du même sexe. </p>
<p>Ni communauté de famille, ni communauté de travail.
Quelle est alors la raison d'être de l'apparente société ? C'est
tout simplement tentative de rapt. L'empressé confrère, sous le
fallacieux prétexte de donner un coup de main, nourrit le projet
de détourner la boule à la première occasion. Faire sa pilule au
tas demande fatigue et patience ; la piller quand elle est faite, ou
du moins s'imposer comme convive, est bien plus commode. Si
la vigilance du propriétaire fait défaut, on prendra la fuite avec
le trésor ; si l'on est surveillé de trop près, on s'attable à deux,
alléguant les services rendus. Tout est profit en pareille tactique ; aussi le pillage est-il exercé comme une industrie des plus
fructueuses. Les uns s'y prennent sournoisement, comme je
viens de le dire ; ils accourent en aide à un confrère qui nullement n'a besoin d'eux, et sous les apparences d'un charitable
secours, dissimulent de très indélicates convoitises. D'autres,
plus hardis peut-être, plus confiants dans leur force, vont droit
au but et détroussent brutalement. </p>
<p>À tout instant des scènes se passent dans le genre de celleci. – Un Scarabée s'en va, paisible, tout seul, roulant sa boule,
propriété légitime, acquise par un travail consciencieux. Un autre survient au vol, je ne sais d'où, se laisse lourdement choir,
replie sous les élytres ses ailes enfumées et du revers de ses
brassards dentés culbute le propriétaire, impuissant à parer l'attaque dans sa posture d'attelage. Pendant que l'exproprié se
démène et se remet sur jambes, l'autre se campe sur le haut de
la boule, position la plus avantageuse pour repousser l'assaillant. Les brassards pliés sous la poitrine et prêt à la riposte, il
attend les événements. Le volé tourne autour de la pelote, cherchant un point favorable pour tenter l'assaut ; le voleur pivote
sur le dôme de la citadelle et constamment lui fait face. Si le
premier se dresse pour l'escalade, le second lui détache un coup
de bras qui l'étend sur le dos. Inexpugnable du haut de son fort,
l'assiégé déjouerait indéfiniment les tentatives de son adversaire
si celui-ci ne changeait de tactique pour rentrer en possession
de son bien. La sape joue pour faire crouler la citadelle avec la garnison. La boule, inférieurement ébranlée, chancelle et roule,
entraînant avec elle le bousier pillard, qui s'escrime de son
mieux pour se maintenir au dessus. Il y parvient, mais non toujours, par une gymnastique précipitée qui lui fait gagner en altitude ce que la rotation du support lui fait perdre. S'il est mis à
pied par un faux mouvement, les chances s'égalisent et la lutte
tourne au pugilat. Voleur et volé se prennent corps à corps, poitrine contre poitrine. Des pattes s'emmêlent et se démêlent, les
articulations s'enlacent, les armures de corne se choquent ou
grincent avec le bruit aigre d'un métal limé. Puis celui des deux
qui parvient à renverser sur le dos son adversaire et à se dégager, à la hâte prend position sur le haut de la boule. Le siège recommence, tantôt par le pillard, tantôt par le pillé, suivant que
l'ont décidé les chances de la lutte corps à corps. Le premier,
hardi flibustier sans doute et coureur d'aventures, fréquemment
a le dessus. Alors, après deux ou trois défaites, l'exproprié se
lasse et revient philosophiquement au tas pour se confectionner
une nouvelle pilule. Quant à l'autre, toute crainte de surprise
dissipée, il s'attelle et pousse où bon lui semble la boule
conquise. J'ai vu parfois survenir un troisième larron qui volait
le voleur. En conscience, je n'en étais pas fâché. </p>
<p>Vainement, je me demande quel est le Proudhon qui a fait
passer dans les mœurs du Scarabée l'audacieux paradoxe : « La
propriété, c'est le vol » ; quel est le diplomate qui a mis en honneur chez les bousiers la sauvage proposition : « La force prime
le droit. » Les données me manquent pour remonter aux causes
de ces spoliations passées en habitude, de cet abus de la force
pour la conquête d'un crottin ; tout ce que je peux affirmer, c'est
que le larcin est, parmi les Scarabées, d'un usage général. Ces
rouleurs de bouse se pillent entre eux avec un sans-gêne dont je
ne connais pas d'autre exemple aussi effrontément caractérisé.
Je laisse aux observateurs futurs le soin d'élucider ce curieux
problème de la psychologie des bêtes, et je reviens aux deux associés roulant de concert leur pilule.</p>
<p>Mais, d'abord, dissipons une erreur qui a cours dans les livres. Je lis dans le magnifique ouvrage de M. Émile Blanchard,
Métamorphoses, Mœurs et Instincts des insectes, le passage
suivant : « Notre insecte se trouve parfois arrêté, par un obstacle insurmontable, la boule est tombée dans un trou. C'est ici
qu'apparaît chez l'Ateuchus<a href="#footnote-2"><sup>2</sup></a> une intelligence de la situation
vraiment étonnante, et une facilité de communication entre les
individus de la même espèce plus surprenante encore. L'impossibilité de franchir l'obstacle avec la boule étant reconnue,
l'Ateuchus semble l'abandonner, il s'envole au loin. Si vous êtes
suffisamment doué de cette grande et noble vertu qu'on appelle
la patience, demeurez près de cette boule laissée à l'abandon :
au bout de quelque temps, l'Ateuchus reviendra à cette place, et
il n'y reviendra pas seul ; il sera suivi de deux, trois, quatre, cinq
compagnons qui s'abattent tous à l'endroit désigné, mettent
leurs efforts en commun pour enlever le fardeau. L'Ateuchus a
été chercher du renfort, et voilà comment, au milieu des champs
arides, il est si ordinaire de voir plusieurs Ateuchus réunis pour
le transport d'une seule boule. » – Je lis enfin dans le Magasin
d'entomologie d'Illiger : – « Un Gymnopleure pilulaire<a href="#footnote-3"><sup>3</sup></a> en
construisant la boule de fiente destinée à renfermer ses œufs, la
fit rouler dans un trou, d'où il s'efforça pendant longtemps de la
tirer tout seul. Voyant qu'il perdait son temps en vains efforts, il
courut à un tas de fumier voisin chercher trois individus de son
espèce, qui, unissant leurs forces aux siennes, parvinrent à retirer la boule de la cavité où elle était tombée, puis retournèrent à
leur fumier continuer leurs travaux. »</p>
<p id="footnote-2"><sup>2</sup> Les Scarabées portent aussi le nom d'Ateuchus. </p>
<p id="footnote-3"><sup>3</sup> Le Gymnopleure pilulaire est un bousier assez voisin du Scarabée mais de plus petite taille. Il roule comme lui des pilules de
bouse ainsi que l'indique son nom. Le Gymnopleure est répandu
partout, même dans le nord ; tandis que le Scarabée sacré ne
s'écarte guère des bords de la Méditerranée. </p>
<p>J'en demande bien pardon à mon illustre maître,
M. Blanchard, mais certainement, les choses ne se passent pas
ainsi. D'abord les deux récits sont tellement conformes, qu'ils
ont sans doute chacun même origine. Illiger, sur une observation trop peu suivie pour mériter confiance aveugle, a mis en
avant l'aventure de son Gymnopleure ; et le même fait a été répété pour les Scarabées, parce que, en effet, il est très commun
de voir deux de ces insectes occupés en commun soit à faire rouler une pilule, soit à la retirer d'un endroit difficile. Mais le
concours de deux ne prouve en rien que le bousier dans l'embarras soit allé requérir main forte auprès des camarades. J'ai
eu, dans une large mesure, la patience que recommande
M. Blanchard ; j'ai vécu de longs jours, pourrais-je dire, en intimité avec le Scarabée sacré ; je me suis ingénié de toutes les
manières pour voir clair, autant que possible, dans ses us et
coutumes et les étudier sur le vif, et je n'ai jamais rien surpris
qui de près ou de loin, fit songer à des compagnons appelés en
aide. Comme je le relaterai bientôt, j'ai soumis le bousier à des
épreuves bien autrement sérieuses que celles d'une cavité où la
pilule aurait pu choir ; je l'ai mis dans des embarras plus graves
que celui d'une pente à remonter, vrai jeu pour le Sisyphe entêté
qui semble se complaire à la rude gymnastique des endroits déclives, comme si la pilule en devenant de la sorte plus ferme,
gagnait ainsi en valeur ; j'ai fait naître par mon artifice des situations où l'insecte avait besoin plus que jamais de secours, et
jamais à mes yeux n'a paru quelque preuve de bons offices entre
camarades. J'ai vu des pillés, j'ai vu des pillards, et rien de plus.
Si plusieurs bousiers entouraient la même pilule, c'est qu'il y
avait bataille. Mon humble avis est donc que quelques Scarabées réunis autour d'une même pelote dans des intentions de
pillage, ont donné lieu à ces récits de camarades appelés pour
donner un coup de main. Des observations incomplètes, ont fait
d'un audacieux détrousseur un compagnon serviable, qui se dérange de son travail pour prêter un coup d'épaule. </p>
<p>Ce n'est pas affaire de faible portée que d'accorder à un insecte une intelligence de la situation vraiment étonnante, et une
facilité de communication entre individus de la même espèce
plus surprenante encore. J'insiste donc sur ce point. Comment ?
Un Scarabée dans la détresse concevrait l'idée d'aller quérir de
l'aide ? Il s'en irait au vol, explorant le pays tout à la ronde, pour
trouver des confrères à l'œuvre autour d'une bouse ; et les trouvant, par une pantomime quelconque, par le geste des antennes
en particulier, il leur tiendrait à peu près ce langage : « Dites
donc, vous autres, ma charge a versé là-bas dans un trou ; venez
m'aider à la retirer. Je vous revaudrai cela dans l'occasion. » Et
les collègues comprendraient ! Et, chose non moins forte, ils
laisseraient aussitôt là leur travail, leur pilule commencée, leur
chère pilule exposée aux convoitises des autres et certainement
pillée en leur absence, pour s'en aller prêter secours au suppliant ! Tant d'abnégation me laisse d'une profonde incrédulité,
que corrobore tout ce que j'ai vu pendant des années et des années, non dans des boites à collection, mais sur les lieux mêmes
de travail du Scarabée. En dehors des soins de la maternité,
soins dans lesquels il est presque toujours admirable, l'insecte, à
moins qu'il ne vive en société, comme les Abeilles, les Fourmis
et les autres, ne se préoccupe d'autre chose que de lui-même. </p>
<p>Mais terminons là cette digression, qu'excuse l'importance
du sujet. J'ai dit qu'un Scarabée, propriétaire d'une boule qu'il
pousse à reculons, est fréquemment rejoint par un confrère, qui
accourt le seconder dans un but intéressé, et le piller si l'occasion s'en présente. Appelons associés, bien que ce ne soit pas là
le mot propre, les deux collaborateurs, dont l'un s'impose et
dont l'autre peut-être, n'accepte des offices étrangers que
crainte d'un mal pire. La rencontre est d'ailleurs des plus pacifiques. Le bousier propriétaire ne se détourne pas un seul instant
de son travail à l'arrivée de l'acolyte ; le nouveau venu semble
animé des meilleures intentions et se met incontinent à l'ouvrage. Le mode d'attelage est différent pour chacun des associés.
Le propriétaire occupe la position principale, la place d'hon-neur : il pousse à l'arrière de la charge, les pattes postérieures
en haut, la tête en bas. L'acolyte occupe le devant, dans une position inverse, la tête en haut, les bras dentés sur la boule, les
longues jambes postérieures sur le sol. Entre les deux, la pilule
chemine, chassée devant par le premier, attirée à lui par le second. </p>
<p>Les efforts du couple ne sont pas toujours bien concordants, d'autant plus que l'aide tourne le dos au chemin à parcourir, et que le propriétaire a la vue bornée par la charge. De là,
des accidents réitérés, de grotesques culbutes dont on prend
gaîment son parti : chacun se ramasse à la hâte et reprend position sans intervertir l'ordre. En plaine, ce mode de charroi ne
répond pas à la dépense dynamique, faute de précision dans les
mouvements combinés ; à lui seul, le Scarabée de l'arrière ferait
aussi vite et mieux. Aussi l'acolyte, après avoir donné des preuves de son bon vouloir, au risque de troubler le mécanisme,
prend-il le parti de se tenir en repos, sans abandonner, bien entendu, la précieuse pelote qu'il regarde comme déjà sienne. Pelote touchée est pelote acquise. Il ne commettra pas cette imprudence : l'autre le planterait là. </p>
<p>Il ramasse donc ses jambes sous le ventre, s'aplatit, s'incruste pour ainsi dire sur la boule et fait corps avec elle. Le tout,
pilule et bousier cramponné à sa surface, roule désormais en
bloc sous la poussée du légitime propriétaire. Que la charge lui
passe sur le corps, qu'il occupe le dessus, le dessous, le côté du
fardeau roulant, peu lui importe ; l'aide tient bon et reste coi.
Singulier auxiliaire, qui se fait carrosser pour avoir sa part de
vivres ! Mais qu'une rampe ardue se présente, et un beau rôle
lui revient. Alors, sur la pente pénible, il se met en chef de file,
retenant de ses bras dentés la pesante masse, tandis que son
confrère prend appui pour hisser la charge un peu plus haut.
Ainsi, à deux, par une combinaison d'efforts bien ménagés, celui
d'en haut retenant, celui d'en bas poussant, je les ai vus gravir
des talus où sans résultat se serait épuisé l'entêtement d'un seul. Mais tous n'ont pas le même zèle en ces moments difficiles : il
s'en trouve qui, sur les pentes où leur concours serait le plus
nécessaire, n'ont pas l'air de se douter le moins du monde des
difficultés à surmonter. Tandis que le malheureux Sisyphe
s'épuise en tentatives pour franchir le mauvais pas, l'autre,
tranquillement laisse faire, incrusté sur la boule, avec elle roulant dans la dégringolade, avec elle hissé derechef. </p>
<p>J'ai soumis bien des fois deux associés à l'épreuve suivante,
pour juger de leurs facultés inventives en un grave embarras.
Supposons-les en plaine, l'acolyte immobile sur la pelote, l'autre
poussant. Avec une longue et forte épingle, sans troubler l'attelage, je cloue au sol la boule, qui s'arrête soudain. Le Scarabée,
non au courant de mes perfidies, croit sans doute quelque obstacle naturel, ornière, racine de chiendent, caillou barrant le
chemin. Il redouble d'efforts, s'escrime de son mieux ; rien ne
bouge. – Que se passe-t-il donc ? Allons voir. – Par deux ou
trois fois, l'insecte fait le tour de sa pilule. Ne découvrant rien
qui puisse motiver l'immobilité, il revient à l'arrière, et pousse
de nouveau. La boule reste inébranlable. – Voyons là-haut. –
L'insecte y monte. Il n'y trouve que son collègue immobile, car
j'avais soin d'enfoncer assez l'épingle pour que la tête disparût
dans la masse de la pelote ; il explore tout le dôme et redescend.
D'autres poussées sont vigoureusement essayées en avant, sur
les côtés ; l'insuccès est le même. Jamais bousier sans doute ne
s'était trouvé en présence d'un pareil problème d'inertie.</p>
<p>Voilà le moment, le vrai moment de réclamer de l'aide,
chose d'autant plus aisée que le collègue est là, tout près, accroupi sur le dôme. Le Scarabée va-t-il le secouer et lui dire
quelque chose comme ceci : « Que fais-tu là, fainéant ! Mais
viens donc voir, la mécanique ne marche plus ! » Rien ne le
prouve, car je vois longtemps le Scarabée s'obstiner à ébranler
l'inébranlable, à explorer d'ici et de là, par dessus, par côté, la
machine immobilisée, tandis que l'acolyte persiste dans son repos. À la longue, cependant, ce dernier a conscience que quel-que chose d'insolite se passe ; il en est averti par les allées et
venues inquiètes du confrère et par l'immobilité de la pilule. Il
descend donc et à son tour examine la chose. L'attelage à deux
ne fait pas mieux que l'attelage à un seul. Ceci se complique. Le
petit éventail de leurs antennes s'épanouit, se ferme, se rouvre,
s'épanouit, se rouvre, s'agite et trahit leur vive préoccupation.
Puis un trait de génie met fin à ces perplexités. « Qui sait ce qu'il
y a là-dessous ? » – La pilule est donc explorée par la base, et
une fouille légère a bientôt mis l'épingle à découvert. Aussitôt il
est reconnu que le nœud de la question est là. </p>
<p>Si j'avais eu voix délibérative au conseil, j'aurais dit : Il faut
pratiquer une excavation et extraire le pieu qui fixe la boule. –
Ce procédé, le plus élémentaire de tous et d'une mise en pratique facile pour des fouilleurs aussi experts, ne fut pas adopté,
pas même essayé. Le bousier trouva mieux que l'homme. Les
deux collègues, qui d'ici, qui de là, s'insinuent sous la boule, laquelle glisse d'autant et remonte le long de l'épingle à mesure
que s'enfoncent les coins vivants. La mollesse de la matière, qui
cède en se creusant d'un canal sous la tête du pieu inébranlable,
permet cette habile manœuvre. Bientôt la pelote est suspendue
à une hauteur, égale à l'épaisseur du corps des Scarabées. Le
reste est plus difficile. Les bousiers, d'abord couchés à plat, se
dressent peu à peu sur les jambes, poussant toujours sur le dos.
C'est dur à venir à mesure que les pattes perdent de leur puissance en se redressant davantage ; mais enfin cela vient. Puis un
moment arrive où la poussée avec le dos n'est plus praticable, la
hauteur limite étant atteinte. Un dernier moyen reste, mais bien
moins favorable au développement de force. Tantôt dans l'une,
tantôt dans l'autre de ses postures d'attelage, c'est-à-dire la tête
en bas ou bien la tête en haut, l'insecte pousse soit avec les pattes postérieures, soit avec les pattes antérieures. Finalement, la
boule tombe à terre, si l'épingle toutefois n'est pas trop longue.
L'éventrement de la pilule par le pieu est tant bien que mal réparé et le charroi aussitôt recommence. </p>
<p>Mais si l'épingle est d'une longueur trop considérable, la
pelote, encore solidement fixée, finit par être suspendue à une
hauteur que l'insecte, se redressant, ne peut plus dépasser. Dans
ce cas, après de vaines évolutions autour du mât de cocagne
inaccessible, les bousiers abandonnent la place si l'on n'a pas la
bonté d'âme d'achever soi-même la besogne et de leur restituer
le trésor. Ou bien encore, on leur vient en aide de la manière
suivante. On exhausse le sol au moyen d'une petite pierre plate,
piédestal du haut duquel il est possible à l'insecte de continuer.
L'utilité de ce secours ne semble pas immédiatement comprise,
car nul des deux ne s'empresse d'en faire profit. Néanmoins, par
hasard ou à dessein, l'un ou l'autre finit par se trouver sur le
haut de la pierre. O bonheur ! en passant, le bousier a senti la
pilule lui effleurer le dos. À ce contact, le courage revient et les
efforts recommencent. Voilà l'insecte qui, sur la secourable
plate-forme, tend les articulations, fait comme on dit le gros dos
et refoule en haut la pilule. Quand le dos ne suffit plus, il manœuvre des pattes, soit droit, soit renversé. Nouvel arrêt et nouveaux signes d'inquiétude lorsque la limite d'extension est atteinte. Alors, sans déranger la bête, sur la première petite pierre
mettons-en une seconde. À l'aide de ce nouveau gradin, point
d'appui pour ses leviers, l'insecte poursuit le travail. En ajoutant
ainsi assise sur assise, à mesure qu'il en était besoin, j'ai vu le
Scarabée, hissé sur une branlante pile de trois à quatre travers
de doigt de hauteur, persister dans son œuvre jusqu'à complet
arrachement de la pilule. </p>
<p>Y avait-il en lui quelque vague connaissance des services
rendus par l'exhaussement de la base d'appui ? Je me permettrai d'en douter, bien que l'insecte ait fort habilement profité de
ma plate-forme de petites pierres. Si, en effet, l'idée si élémentaire de faire usage d'une base plus haute pour atteindre à un
objet trop élevé ne dépassait la portée de ses facultés, comment
se fait-il qu'étant deux, nul ne songe à prêter son dos à l'autre
pour l'élever d'autant et lui rendre ainsi le travail possible ? L'un
aidant l'autre, ils doubleraient l'altitude gagnée. Ah ! qu'ils son loin de semblable combinaison ! Chacun pousse à la boule, du
mieux qu'il peut, il est vrai ; mais il pousse comme s'il était seul
et sans paraître soupçonner l'heureux résultat qu'amènerait une
manœuvre d'ensemble. Ils font là, sur la pilule clouée à terre par
une épingle, ce qu'ils font dans des circonstances analogues,
lorsque la charge est arrêtée par un obstacle, retenue par un
lacet de chiendent, ou bien fixée en place par quelque menu
bout de tige qui s'est implanté dans la masse molle et roulante.
Mes artifices ont réalisé une condition d'arrêt peu différente au
fond, de celles qui doivent naturellement se produire quand la
pilule roule au milieu des mille accidents du terrain ; et l'insecte
agit, dans mes épreuves expérimentales, comme il agirait en
toute autre circonstance où je ne serai pas intervenu. Il fait coin
et levier avec le dos, il pousse avec les pattes, sans rien innover
dans ses moyens d'action, même lorsqu'il pourrait disposer du
concours d'un confrère. </p>
<p>S'il est tout seul en face des difficultés de la boule clouée au
sol, s'il n'a pas d'acolyte, ses manœuvres dynamiques restent
absolument les mêmes, et ses efforts aboutissent à un succès,
pourvu qu'on lui donne l'indispensable appui de la plate-forme,
édifiée petit à petit. Si pareil secours lui est refusé, le Scarabée,
que le toucher de sa chère pilule trop élevée ne stimule plus, se
décourage et, tôt ou tard, à son grand regret, sans doute, s'envole et disparaît. Où va-t-il ? Je l'ignore. Ce que je sais fort bien,
c'est qu'il ne revient pas avec une escouade de compagnons
priés de lui venir en aide. Qu'en ferait-il, lui qui ne sait pas utiliser la présence d'un confrère quand la pilule est part à deux ? </p>
<p>Mais peut-être mon expérience, dont le résultat est la suspension de la boule à une hauteur inaccessible lorsque sont
épuisés les moyens d'action de l'insecte, sort-elle un peu trop
des habituelles conditions. Essayons alors une fossette assez
profonde et assez escarpée pour que le bousier, déposé avec sa
pelote au fond du trou, ne puisse remonter la paroi en roulant
sa charge. Voilà bien les conditions exactes citées par MM. Blanchard et Illiger. Or, qu'advient-il dans ce cas ? Lorsque des efforts obstinés, mais sans résultat aucun, l'ont
convaincu de son impuissance, le bousier prend son vol et disparaît. Longtemps, très-longtemps, sur la foi des maîtres, j'ai
attendu le retour de l'insecte avec le renfort de quelques amis ;
j'ai toujours attendu en vain. Maintes fois aussi, il m'est arrivé
de retrouver, plusieurs jours après, la pilule sur les lieux mêmes
de l'expérience, au sommet de l'épingle ou bien au fond du
trou ; preuve qu'en mon absence rien de nouveau ne s'était passé. Pilule délaissée pour cause de force majeure, est pilule abandonnée sans retour, sans tentatives de sauvetage avec secours
d'autrui. Savant emploi du coin et du levier pour remettre en
marche la boule immobilisée, telle est donc en somme la plus
haute prouesse intellectuelle dont m'ait rendu témoin le Scarabée sacré. En dédommagement de ce que l'expérience nie, savoir l'appel entre confrères à un coup de main, très volontiers je
transmets ce haut fait mécanique à l'histoire pour la glorification des bousiers. </p>
<p>Orientés au hasard, à travers plaines de sable, fourrés de
thym, ornières et talus, les deux Scarabées collègues quelque
temps roulent la pelote et lui donnent ainsi une certaine fermeté
de pâte qui peut-être est de leur goût. Tout chemin faisant, un
endroit favorable est adopté. Le bousier propriétaire, celui qui
s'est maintenu toujours à la place d'honneur, à l'arrière de la
pilule, celui enfin qui presque à lui seul a fait tous les frais du
charroi, se met à l'œuvre pour creuser la salle à manger. Tout à
côté de lui est la boule, sur laquelle l'acolyte reste cramponné et
fait le mort. Le chaperon et les jambes dentées attaquent le sable ; les déblais sont rejetés à reculons par brassées, et l'excavation rapidement avance. Bientôt l'insecte disparaît en entier
dans l'antre ébauché. Toutes les fois qu'il revient à ciel ouvert
avec sa brassée de déblais, le fouisseur ne manque pas de donner un coup d'œil à sa pelote pour s'informer si tout va bien. De
temps à autre, il la rapproche du seuil du terrier ; il la palpe, et à
ce contact, il semble acquérir un redoublement de zèle. L'autre, sainte-nitouche, par son immobilité sur la boule, continue à
inspirer confiance. Cependant la salle souterraine s'élargit et
s'approfondit ; le fouisseur fait de plus rares apparitions, retenu
qu'il est par l'ampleur des travaux. Le moment est bon. L'endormi se réveille, l'astucieux acolyte décampe chassant derrière
lui la boule avec la prestesse d'un larron qui ne veut pas être
pris sur le fait. Cet abus de confiance m'indigne, mais je laisse
faire dans l'intérêt de l'histoire : il me sera toujours temps d'intervenir pour sauvegarder la morale si le dénouement menace
de tourner à mal. </p>
<p>Le voleur est déjà à quelques mètres de distance. Le volé
sort du terrier, regarde et ne trouve plus rien. Coutumier du fait
lui-même, sans doute, il sait ce que cela veut dire. Du flair et du
regard, la piste est bientôt trouvée. À la hâte, le bousier rejoint
le ravisseur ; mais celui-ci, roué compère, dès qu'il se sent talonné de près, change de mode d'attelage, se met sur les jambes
postérieures et enlace la boule avec ses bras dentés, comme il le
fait en ses fonctions d'aide. – « Ah ! mauvais drôle ! j'évente ta
mèche : tu veux alléguer pour excuse que la pilule a roulé sur la
pente et que tu t'efforces de la retenir et de la ramener au logis.
Pour moi, témoin impartial de l'affaire, j'affirme que la boule
bien équilibrée à l'entrée du terrier n'a pas roulé d'elle-même :
d'ailleurs le sol est en plaine ; j'affirme t'avoir vu mettre la pelote en mouvement et t'éloigner avec des intentions non équivoques. C'est une tentative de rapt, ou je ne m'y connais pas. » –
Mon témoignage n'étant pas pris en considération, le propriétaire accueille débonnairement les excuses de l'autre ; et les
deux, comme si de rien n'était, ramènent la pilule au terrier. </p>
<p>Mais si le voleur a le temps de s'éloigner assez, ou s'il parvient à celer la piste par quelque adroite contremarche, le mal
est irréparable. Avoir amassé des vivres sous les feux du soleil,
les avoir péniblement voiturés au loin, s'être creusé dans le sable une confortable salle de banquet, et au moment où tout est
prêt, quand l'appétit aiguisé par l'exercice ajoute de nouveaux charmes à la perspective de la prochaine bombance, se trouver
tout à coup dépossédé par un astucieux collaborateur, c'est, il
faut en convenir, un revers de fortune qui ébranlerait plus d'un
courage. Le bousier ne se laisse pas abattre par ce mauvais coup
du sort : il se frotte les joues, épanouit les antennes, hume l'air
et prend son vol vers le tas prochain pour recommencer à nouveau. J'admire et j'envie cette trempe de caractère. </p>
<p>Supposons le Scarabée assez heureux pour avoir trouvé un
associé fidèle ; ou, ce qui est mieux, supposons qu'il n'ait pas
rencontré en route de confrère s'invitant lui-même. Le terrier
est prêt. C'est une cavité creusée en terrain meuble, habituellement dans le sable, peu profonde, du volume du poing, et communiquant au dehors par un court goulot, juste suffisant au
passage de la pilule. Aussitôt les vivres emmagasinés, le Scarabée s'enferme chez lui en bouchant l'entrée du logis avec des
déblais tenus en réserve dans un coin. La porte close, rien au
dehors ne trahit la salle du festin. Et maintenant vive la joie ;
tout est pour le mieux dans le meilleur des mondes ! La table est
somptueusement servie ; le plafond tamise les ardeurs du soleil
et ne laisse pénétrer qu'une chaleur douce et moite ; le recueillement, l'obscurité, le concert extérieur des grillons, tout favorise les fonctions du ventre. Dans mon illusion, je me suis surpris à écouter aux portes, croyant ouïr, pour couplets de table, le
fameux morceau de l'opéra de Galathée : « Ah ! qu'il est doux de
ne rien faire, quand tout s'agite autour de nous. » </p>
<p>Qui oserait troubler les béatitudes d'un pareil banquet ?
Mais le désir d'apprendre est capable de tout, et cette audace, je
l'ai eue. J'inscris ici le résultat de mes violations de domicile. –
À elle seule, la pilule presque en entier remplit la salle ; la somptueuse victuaille s'élève du plancher au plafond. Une étroite galerie la sépare des parois. Là se tiennent les convives, deux ou
plus, un seul très souvent, le ventre à table, le dos à la muraille.
Une fois la place choisie, on ne bouge plus, toutes les puissances
vitales sont absorbées par les facultés digestives. Pas de menus ébats, qui feraient perdre une bouchée, pas d'essais dédaigneux,
qui gaspilleraient les vivres. Tout doit y passer, par ordre et religieusement. À les voir si recueillis autour de l'ordure, on dirait
qu'ils ont conscience de leur rôle d'assainisseurs de la terre, et
qu'ils se livrent avec connaissance de cause à cette merveilleuse
chimie qui de l'immondice fait la fleur, joie des regards, et l'élytre des Scarabées, ornement des pelouses printanières. Pour ce
travail transcendant qui doit faire matière vivante des résidus
non utilisés par le cheval et le mouton, malgré la perfection de
leurs voies digestives, le bousier doit être outillé d'une manière
particulière. Et, en effet, l'anatomie nous fait admirer la prodigieuse longueur de son intestin, qui, plié et replié sur lui-même,
lentement élabore les matériaux en ses circuits multipliés et les
épuise jusqu'au dernier atome utilisable. D'où l'estomac de
l'herbivore n'a rien pu retirer, ce puissant alambic extrait des
richesses qui, par une simple retouche, deviennent armure
d'ébène chez le Scarabée sacré, cuirasse d'or et de rubis chez
d'autres bousiers. </p>
<p>Or cette admirable métamorphose de l'ordure doit s'accomplir dans le plus bref délai : la salubrité générale l'exige.
Aussi le Scarabée est-il doué d'une puissance digestive peut-être
sans exemple ailleurs. Une fois en loge avec des vivres, jour et
nuit il ne cesse de manger et de digérer jusqu'à ce que les provisions soient épuisées. La preuve en est palpable. Ouvrons la cellule où le bousier s'est retiré de ce monde. À toute heure du jour
nous trouverons l'insecte attablé, et derrière lui, appendu encore à l'animal, un cordon continu grossièrement enroulé à la
façon d'un tas de câbles. Sans explications délicates à donner,
aisément on devine ce que le dit cordon représente. La volumineuse boule passe, bouchée par bouchée, dans les voies digestives de l'insecte, cède ses principes nutritifs, et reparaît du côté
opposé filée en cordon. Eh bien, ce cordon sans rupture, souvent d'une seule pièce, toujours appendu à l'orifice de la filière,
prouve surabondamment, sans autres observations, la continuité de l'acte digestif. Quand les provisions touchent à leur fin, le câble déroulé est d'une longueur étonnante : cela se mesure par
pans. Où trouver le pareil de tel estomac qui, de si triste pitance,
afin que rien ne se perde au bilan de la vie, fait régal une semaine, des quinze jours durant sans discontinuer. </p>
<p>Toute la pelote passée à la filière, l'ermite reparaît au jour,
cherche fortune, trouve, se façonne une nouvelle boule et recommence. Cette vie de liesse dure un à deux mois, de mai en
juin ; puis quand viennent les fortes chaleurs aimées des Cigales, les Scarabées prennent leurs quartiers d'été et s'enfouissent
au frais dans le sol. Ils reparaissent aux premières pluies d'automne, moins nombreux, moins actifs qu'au printemps, mais
occupés alors apparemment de l'œuvre capitale, de l'avenir de
leur race. </p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>



  </div>
</template>

<style>
  .home{
    text-align: left;
  }
  p {
   text-indent: 1em;
  }
  blockquote {
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    font-style: italic;
  }
  dt {
		margin-top: 1em;
		clear: left;
	}
	dd {
		margin-left: 1em;
    margin-bottom: 1em;
	}
</style>

<script>
import Tooltip from '@/components/Tooltip'
export default {
  name: 'Home',
  components: {Tooltip},
}
</script>
<template>
  <div class="home ml-16 mr-16 mt-5">
<h2>1</h2>
<p>Le père Barbeau de la Cosse n’était pas mal
dans ses <Tooltip label="暮らし向き、経済状態">affaires</Tooltip>, <Tooltip label="その証拠に">à preuve qu</Tooltip>’il était du <Tooltip label="市議会">conseil
municipal</Tooltip> de sa commune. Il avait deux <Tooltip label="畑">champs</Tooltip>
qui lui donnaient la nourriture de sa famille et du
profit <Tooltip label="[par-dessus le marché] そのうえ、おまけに">pardessus le marché</Tooltip>. Il <Tooltip label="摘む">cueillait</Tooltip> dans ses
<Tooltip label="牧草地">prés</Tooltip> du <Tooltip label="干し草">foin</Tooltip> <Tooltip label="いっぱいに">à pleins</Tooltip> <Tooltip label="荷車">charrois</Tooltip>, et, <Tooltip label="[positive な物の中で例外的にnegatifな]…を除いて">sauf celui qui</Tooltip>
était au <Tooltip label="ふち、へり、岸、ほとり">bord</Tooltip> du <Tooltip label="小川">ruisseau</Tooltip>, et qui était un peu
<Tooltip label="困らせる、邪魔する、うんざりさせる">ennuyé</Tooltip> par le <Tooltip label="イグサ">jonc</Tooltip>, c’était du <Tooltip label="まぐさ">fourrage</Tooltip> connu
dans l’<Tooltip label="場所">endroit</Tooltip> pour être de première qualité.</p>
<p>La maison du père Barbeau était <Tooltip label="骨組みのしっかりした、できの良い、かっこいい">bien bâtie,</Tooltip>
couverte en <Tooltip label="瓦">tuile</Tooltip>, <Tooltip label="築く">établie</Tooltip> en bon air sur la côte,
avec un jardin de bon <Tooltip label="収穫">rapport</Tooltip> et une vigne de six
journaux. Enfin il avait, derrière sa <Tooltip label="納屋">grange</Tooltip>, un
beau <Tooltip label="果樹園">verger</Tooltip>, que nous appelons chez nous une
ouche, où le fruit abondait tant en prunes qu’en
<Tooltip label="黒さくらんぼ">guignes</Tooltip>, en <Tooltip label="洋梨">poires</Tooltip> et en cormes. <Tooltip label="同様に">Mêmement</Tooltip> les
<Tooltip label="くるみ">noyers</Tooltip> de ses <Tooltip label="縁、へり">bordures</Tooltip> étaient les plus vieux et
les plus gros de deux lieues aux entours.</p>
<p>Le père Barbeau était un homme de bon
courage, pas méchant, et très porté pour sa
famille, sans être injuste à ses voisins et
<Tooltip label="小教区の信者">paroissiens</Tooltip>.</p>
<p>Il avait déjà trois enfants, quand la mère
Barbeau, voyant sans doute qu’elle avait assez de
bien pour cinq, et qu’il fallait se dépêcher, parce
que l’âge lui venait, s’avisa de lui en donner deux
à la fois, deux beaux garçons, et, comme ils
étaient si pareils qu’on ne pouvait <Tooltip label="ほとんど">presque</Tooltip> pas les
distinguer l’un de l’autre, on reconnut bien vite
que c’étaient deux <Tooltip label="双子">bessons</Tooltip>, <Tooltip label="つまり">c’est-à-dire</Tooltip> deux
<Tooltip label="双子">jumeaux</Tooltip> d’une parfaite ressemblance.</p>
<p>La mère Sagette, qui les reçut dans son <Tooltip label="エプロン">tablier</Tooltip>
comme ils venaient au monde, n’oublia pas de
faire au <Tooltip label="第一子">premier-né</Tooltip> une petite croix sur le bras
avec son aiguille, parce que, disait-elle, un bout
de ruban ou un <Tooltip label="首飾り">collier</Tooltip> peut <Tooltip label="混同される">se confondre</Tooltip> et faire
perdre le <Tooltip label="調子相続権">droit d’aînesse</Tooltip>. Quand l’enfant sera plus
fort, dit-elle, il faudra lui faire une marque qui ne
puisse jamais <Tooltip label="消える">s’effacer</Tooltip> ; à quoi l’on ne manqua
pas. L’<Tooltip label="長男">aîné</Tooltip> fut nommé Sylvain, dont on fit
bientôt Sylvinet, pour le distinguer de son frère
aîné, qui lui avait servi de <Tooltip label="[洗礼の]代父">parrain</Tooltip> ; et le <Tooltip label="弟">cadet</Tooltip> fut
appelé Landry, nom qu’il garda comme il l’avait
reçu au <Tooltip label="洗礼">baptême</Tooltip> , parce que son oncle, qui était
son parrain, avait gardé de son jeune âge la
coutume d’être appelé Landriche.</p>
<p>Le père Barbeau fut un peu étonné, quand il
revint du marché, de voir deux petites têtes dans
le <Tooltip label="ゆりかご">berceau</Tooltip>. – Oh ! oh ! fit-il, voilà un berceau qui
est trop <Tooltip label="幅の狭い">étroit</Tooltip>. Demain matin, il me faudra
l’<Tooltip label="広げる">agrandir</Tooltip>. – Il était un peu <Tooltip label="木工職人">menuisier</Tooltip> de ses
mains, sans avoir appris, et il avait fait la moitié
de ses <Tooltip label="家具">meubles</Tooltip>. Il ne s’étonna pas autrement et
alla soigner sa femme, qui but un grand verre de
vin chaud, et ne s’en porta que mieux.</p>
<p>– Tu travailles si bien, ma femme, lui dit-il,
que ça doit me donner du courage. Voilà deux
enfants de plus à <Tooltip label="不要する">nourrir</Tooltip>, dont nous n’avions pas
absolument besoin ; ça veut dire qu’il ne faut pas
que je me repose de cultiver nos terres et d’élever
nos <Tooltip label="家畜">bestiaux</Tooltip>. Sois tranquille ; on travaillera ;
mais ne m’en donne pas trois la prochaine fois,
car ça serait trop.</p>
<p>La mère Barbeau se prit à pleurer, dont le père
Barbeau se mit fort en peine.</p>
<p>– Bellement, bellement, dit-il, il ne faut te
chagriner, ma bonne femme. Ce n’est pas par
manière de reproche que je t’ai dit cela, mais par
manière de remerciement, bien au contraire. Ces
deux enfants-là sont beaux et <Tooltip label="立派">bien faits</Tooltip> ; ils n’ont
point de défauts sur le corps, et j’en suis content.</p>
<p>– Alas ! mon Dieu, dit la femme, je sais bien
que vous ne me les <Tooltip label="咎める">reprochez</Tooltip> pas, notre maître ;
mais moi j’ai du souci, parce qu’on m’a dit qu’il
n’y avait rien de plus <Tooltip label="運のいい">chanceux</Tooltip> et de plus <Tooltip label="困難">malaisé</Tooltip>
à élever que des bessons. Ils se font tort l’un à
l’autre, et presque toujours, il faut qu’un des deux
<Tooltip label="命を落とす">périsse</Tooltip>  pour que l’autre se porte bien.</p>
<p>– Oui-da ! dit le père : est-ce la vérité ? <Tooltip label="私はといえば">Tant
qu’à moi</Tooltip>, ce sont les premiers bessons que je
vois. Le cas n’est point fréquent. Mais voici la
mère Sagette qui a de la connaissance <Tooltip label="その点について">là-dessus</Tooltip>,
et qui va nous dire ce qui en est.</p>
<p>La mère Sagette étant appelée répondit :</p>
<p>– Fiez-vous à moi ; ces deux bessons-là
vivront bel et bien, et ne seront pas plus malades
que d’autres enfants. Il y a cinquante ans que je
fais le métier de sage-femme, et que je vois
naître, vivre ou mourir tous les enfants du canton.
Ce n’est donc pas la première fois que je reçois
des jumeaux. D’abord, la ressemblance ne fait
rien à leur santé. Il y en a qui ne se ressemblent
pas plus que vous et moi, et souvent il arrive que
l’un est fort et l’autre faible ; ce qui fait que l’un
vit et que l’autre meurt ; mais regardez les vôtres,
ils sont chacun aussi beau et aussi bien corporé
que s’il était fils unique. Ils ne se sont donc pas
fait dommage l’un à l’autre dans le sein de leur
mère ; ils sont venus à bien tous les deux sans
trop la faire souffrir et sans souffrir eux-mêmes.
Ils sont jolis à merveille et ne demandent qu’à
vivre. Consolez-vous donc, mère Barbeau, ça
vous sera un plaisir de les voir grandir ; et, s’ils
continuent, il n’y aura guère que vous et ceux qui
les verront tous les jours qui pourrez faire entre
eux une différence ; car je n’ai jamais vu deux
bessons si pareils. On dirait deux petits perdreaux
sortant de l’œuf ; c’est si gentil et si semblable,
qu’il n’y a que la mère-perdrix qui les
reconnaisse.</p>
<p>– À la bonne heure ! fit le père Barbeau en se
grattant la tête ; mais j’ai ouï dire que les bessons
prenaient tant d’amitié l’un pour l’autre, que
quand ils se quittaient ils ne pouvaient plus vivre,
et qu’un des deux, tout au moins, se laissait
consumer par le chagrin, jusqu’à en mourir.</p>
<p>– C’est la vraie vérité, dit la mère Sagette ;
mais écoutez ce qu’une femme d’expérience va
vous dire. Ne le mettez pas en oubliance ; car,
dans le temps où vos enfants seront en âge de
vous quitter, je ne serai peut-être plus de ce
monde pour vous conseiller. Faites attention, dès
que vos bessons commenceront à se reconnaître,
de ne pas les laisser toujours ensemble. Emmenez
l’un au travail pendant que l’autre gardera la
maison. Quand l’un ira pêcher, envoyez l’autre à
la chasse ; quand l’un gardera les moutons, que
l’autre aille voir les bœufs au pacage ; quand
vous donnerez à l’un du vin à boire, donnez à
l’autre un verre d’eau, et réciproquement. Ne les
grondez point ou ne les corrigez point tous les
deux en même temps ; ne les habillez pas de
même ; quand l’un aura un chapeau, que l’autre
ait une casquette, et que surtout leurs blouses ne
soient pas du même bleu. Enfin, par tous les
moyens que vous pourrez imaginer, empêchez-les
de se confondre l’un avec l’autre et de
s’accoutumer à ne pas se passer l’un de l’autre.
Ce que je vous dis là, j’ai grand’peur que vous ne
le mettiez dans l’oreille du chat ; mais si vous ne
le faites pas, vous vous en repentirez grandement
un jour.</p>
<p>La mère Sagette parlait d’or et on la crut. On
lui promit de faire comme elle disait, et on lui fit
un beau présent avant de la renvoyer. Puis
comme elle avait bien recommandé que les
bessons ne fussent point nourris du même lait, on
s’enquit vitement d’une nourrice.</p>
<p>Mais il ne s’en trouva point dans l’endroit. La
mère Barbeau, qui n’avait pas compté sur deux
enfants, et qui avait nourri elle-même tous les
autres, n’avait pas pris ses précautions à l’avance.
Il fallut que le père Barbeau partît pour chercher
cette nourrice dans les environs ; et pendant ce
temps, comme la mère ne pouvait pas laisser pâtir
ses petits, elle leur donna le sein à l’un comme à
l’autre.</p>
<p>Les gens de chez nous ne se décident pas vite,
et, quelque riche qu’on soit, il faut toujours un
peu marchander. On savait que les Barbeau
avaient de quoi payer, et on pensait que la mère,
qui n’était plus de la première jeunesse, ne
pourrait point garder deux nourrissons sans
s’épuiser. Toutes les nourrices que le père
Barbeau put trouver lui demandèrent donc dixhuit livres par mois, ni plus ni moins qu’à un
bourgeois.</p>
<p>Le père Barbeau n’aurait voulu donner que
douze ou quinze livres, estimant que c’était
beaucoup pour un paysan. Il courut de tous les
côtés et disputa un peu sans rien conclure.
L’affaire ne pressait pas beaucoup ; car deux
enfants si petits ne pouvaient pas fatiguer la
mère, et ils étaient si bien portants, si tranquilles,
si peu braillards l’un et l’autre, quels ne faisaient
presque pas plus d’embarras qu’un seul dans la
maison. Quand l’un dormait, l’autre dormait
aussi. Le père avait arrangé le berceau, et quand
ils pleuraient tous deux à la fois, on les berçait et
on les apaisait en même temps.</p>
<p>Enfin le père Barbeau fit un arrangement avec
une nourrice pour quinze livres, et il ne se tenait
plus qu’à cent sous d’épingles, lorsque sa femme
lui dit :</p>
<p>– Bah ! notre maître, je ne vois pas pourquoi
nous allons dépenser cent quatre-vingts ou deux
cents livres par an, comme si nous étions des
messieurs et dames, et comme si j’étais hors
d’âge pour nourrir mes enfants. J’ai plus de lait
qu’il n’en faut pour cela. Ils ont déjà un mois, nos
garçons, et voyez s’ils ne sont pas en bon état !
La Merlaude que vous voulez donner pour
nourrice à un des deux n’est pas moitié si forte et
si saine que moi ; son lait a déjà dix-huit mois, et
ce n’est pas ce qu’il faut à un enfant si jeune. La
Sagette nous a dit de ne pas nourrir nos bessons
du même lait, pour les empêcher de prendre trop
d’amitié l’un pour l’autre c’est vrai qu’elle l’a
dit ; mais n’a-t-elle pas dit aussi qu’il fallait les
soigner également bien, parce que, après tout, les
bessons n’ont pas la vie tout à fait aussi forte que
les autres enfants ? J’aime mieux que les nôtres
s’aiment trop, que s’il faut sacrifier l’un à l’autre.
Et puis, lequel des deux mettrons-nous en
nourrice ? Je vous confesse que j’aurais autant de
chagrin à me séparer de l’un comme de l’autre. Je
peux dire que j’ai bien aimé tous mes enfants,
mais, je ne sais comment la chose se fait, m’est
avis que ceux-ci sont encore les plus mignons et
les plus gentils que j’aie portés dans mes bras.
J’ai pour eux un je ne sais quoi qui me fait
toujours craindre de les perdre. Je vous en prie,
mon mari, ne pensez plus à cette nourrice ; nous
ferons pour le reste tout ce que la Sagette a
recommandé.</p>
<p>Comment voulez-vous que des enfants à la
mamelle se prennent de trop grande amitié, quand
c’est tout au plus s’ils connaîtront leurs mains
d’avec leurs pieds quand ils seront en sevrage ?</p>
<p>– Ce que tu dis là n’est pas faux, ma femme,
répondit le père Barbeau en regardant sa femme,
qui était encore fraîche et forte comme on en voit
peu ; mais si, pourtant, à mesure que ces enfants
grossiront, ta santé venait à dépérir ?</p>
<p>– N’ayez peur, dit la Barbeaude, je me sens
d’aussi bon appétit que si j’avais quinze ans, et
d’ailleurs, si je sentais que je m’épuise, je vous
promets que je ne vous le cacherais pas, et il
serait toujours temps de mettre un de ces pauvres
enfants hors de chez nous.</p>
<p>Le père Barbeau se rendit, d’autant plus qu’il
aimait bien autant ne pas faire de dépense inutile.
La mère Barbeau nourrit ses bessons sans se
plaindre et sans souffrir, et même elle était d’un
si beau naturel que, deux ans après le sevrage de
ses petits, elle mit au monde une jolie petite fille,
qui eut nom Nanette, et qu’elle nourrit aussi ellemême. Mais c’était un peu trop, et elle eût eu
peine à en venir à bout, si sa fille aînée, qui était à
son premier enfant, ne l’eût soulagée de temps en
temps, en donnant le sein à sa petite sœur.</p>
<p>De cette manière toute la famille grandit et
grouilla bientôt au soleil, les petits oncles et les
petites tantes avec les petits neveux et les petites
nièces, qui n’avaient pas à se reprocher d’être
beaucoup plus turbulents ou plus raisonnables les
uns que les autres.</p>
  </div>
</template>

<style>
</style>

<script>
import Tooltip from '@/components/Tooltip'
export default {
  components: {Tooltip},
}
</script>
